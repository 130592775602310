.btn {
    @apply rounded-md font-bold
}

.btn-primary {
    @apply bg-Yellow-default 
    hover:bg-Yellow-hover 
    focus:bg-Yellow-pressed
    disabled:bg-Grey-light-2 disabled:text-Grey-dark
}
    
.btn-secondary {
    @apply border border-black bg-white
    hover:bg-Grey-background-2
    focus:bg-Grey-light focus:border-2
    disabled:bg-Grey-light-2 disabled:text-Grey-dark
}

.btn-ghost-dark {
    @apply
    focus:bg-Grey-light
    disabled:bg-Grey-light-2 disabled:text-Grey-dark
}

.btn-ghost-light {
    @apply bg-Grey-background-2 text-white
    hover:bg-Grey-tint
    focus:bg-Grey-dark
    disabled:bg-Grey-background-2 disabled:text-Grey-dark
}

.btn-medium {
    @apply px-4 py-3.5 
}

.btn-small {
    @apply px-4 py-1.5 
}